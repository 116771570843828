export default {
  methods: {
    // Front
    goRoot () {
      this.$router.push({ name: 'Root' })
    },
    goAbout () {
      this.$router.push({ name: 'About' })
    },
    goAcademy () {
      this.$router.push({ name: 'Academy' })
    },
    // -- Articles
    goHeatloss () {
      this.$router.push({ name: 'RaschetTeplopoter' })
    },
    goFloor () {
      this.$router.push({ name: 'RaschetTeplogoPola' })
    },
    goRadiator () {
      this.$router.push({ name: 'RaschetRadiatorov' })
    },
    goWhy () {
      this.$router.push({ name: 'ZachemNuzhnyRaschety' })
      this.$reachGoal('CLICK_TO_ARTICLE')
    },
    goAcademyVideo (id) {
      this.$router.push({ name: 'AcademyVideo', params: { id } })
    },
    // Auth
    goSignIn () {
      this.$router.push({ name: 'SignIn' })
      this.$reachGoal('LOGIN')
    },
    goSignUp () {
      this.$router.push({ name: 'SignUp' })
      this.$reachGoal('CHECK')
    },
    goPasswordRecovery () {
      this.$router.push({ name: 'PasswordRecoveryRequest' })
    },
    goDocs () {
      window.location.href = process.env.VUE_APP_DOCS_URL
    },
    goDemoDocs () {
      window.location.href = process.env.VUE_APP_DOCS_URL + '/demo'
      this.$reachGoal('VISIT_DEMO')
    },
    goBlog () {
      this.$router.push({ name: 'Blog' })
    },
    goPaymentPlans () {
      this.$router.push({ name: 'PaymentPlans' })
    }
  }
}
